<template>
  <div style=" background: white;
        padding: 20px;border-right: 6px;">
    <div>
      <search-com @onSubmit="search" @reset="search"></search-com>
    </div>
    <el-table
        :data="rechargeList"
        style="width: 100%">
      <el-table-column
          label="序号"
          type="index"
         >
      </el-table-column>

      <el-table-column
          prop="name"
          label="打款人名称">
      </el-table-column>

      <el-table-column
          prop="recAccount"
          label="打款账户">
      </el-table-column>

      <el-table-column
          prop="amount"
          label="充值金额">
      </el-table-column>

      <el-table-column
          label="充值凭证">
        <template slot-scope="scope" >
          <sb-image
              width="50px"
              height="50px"
              :src="scope.row.rechargeScree"
              :src-list="[scope.row.rechargeScree]">
          </sb-image>
        </template>
      </el-table-column>
      <el-table-column
          label="状态">
        <template slot-scope="scope" >
          <el-tag :color="scope.row.statusStyle" size="mini" style="color: #fff">
            {{scope.row.statusLabel}}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
          prop="submitsTime"
          label="创建时间">
      </el-table-column>

    </el-table>
    <div style="height: 65px">
      <el-pagination
          style="float: right;margin-top: 20px"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[50, 200, 800, 1200]"
          layout="total, pager,  sizes"
          :total="total">
      </el-pagination>

    </div>
  </div>

</template>

<script>
import {RECHARGELIST} from "../../api/base";
import SearchCom from './components/searForm';
import SbImage from '../../components/image/image'

export default {
  name: "rechargeList",
  data(){
    return {
      rechargeList:[],
      total:0,
      listForm:{
        pageIndex:1,
        pageSize:50,
        // beginDate:'',
        // endDate:'',
      }
    }
  },
  components:{
    SearchCom,
    SbImage,
  },
  mounted() {
    // this.listForm.beginDate = this.formmateTime(new Date())
    // this.listForm.endDate= this.formmateTime(new Date())
    this.getList()
  },
  methods:{
    // formmateTime(time){
    //   let nowDate = new Date(time);
    //   let year = nowDate.getFullYear();
    //   let month = nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1;
    //   let day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate  .getDate();
    //   return  year + "-" + month + "-" + day;
    // },
    handleSizeChange(val){
      this.listForm.pageSize = val;
      this.getList()
    },
    handleCurrentChange(val){
      this.listForm.pageIndex = val;
      this.getList()
    },
    search(form){
      this.listForm.pageIndex = 1;
      Object.assign(this.listForm,form)
      this.getList()
    },
    getList(){
      this.$axios.get(RECHARGELIST,this.listForm).then(resp=>{
        if(resp.code == 200){
          this.rechargeList = resp.data.data;
          this.total = resp.data.totalRow;
        }
      })
    }
  },
}
</script>

<style scoped>

</style>