<template>
    <div>
        <el-form :inline="true" :model="formInline" >


          <el-form-item label="充值状态">
              <el-select v-model="formInline.auditStatus" placeholder="请选择充值状态"  class="inputWidth" clearable filterable >
                  <el-option :label="item.name" :value="item.id" v-for="(item,index) in statusList" :key="index"></el-option>
              </el-select>
          </el-form-item>

          <el-form-item>
              <el-button type="primary" @click="onSubmit" icon="el-icon-search" >查询</el-button>
              <el-button type="primary" @click="reset" icon="el-icon-refresh">重置</el-button>
          </el-form-item>

        </el-form>
    </div>
</template>

<script>

    const formInline = {
      auditStatus: '',
    };
    const statusList = [
      {id:1,name:'待审核'},
      {id:2,name:'已通过'},
      {id:3,name:'不通过'},
    ]
    export default {
        name: "taskList",
        props:{


        },
        data() {
            return {
              formInline,
              rechargeList:[],
              statusList,
            }
        },
        methods: {

            onSubmit(){
                this.$emit('onSubmit',this.formInline)
            },
            exportExcel(){
                this.$emit('exportExcel')
            },
            reset(){
                for(let key in this.formInline ){
                    this.formInline[key] = undefined;
                }
                this.times = [new Date(),new Date()]
                this.change(this.times)
                this.$emit('reset',formInline)
            },
        },
        components: {

        },
        mounted() {
        }
    }
</script>

<style scoped>
    .inputWidth{
        width: 230px;
    }
</style>